<template>
	<div
		:class="[
			{ active: activeMember.userId == member.userId },
			'chat-group-member',
		]"
		:style="{ height: height + 'px' }"
	>
		<div class="member-avatar">
			<el-image fit="cover" class="avatar" :src="member.userAvatar" />
		</div>
		<div class="member-name" :style="{ 'line-height': height + 'px' }">
			<div>{{member.alias || member.nickName || member.userName }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'groupMember',
	data() {
		return {};
	},
	props: {
		member: {
			type: Object,
			required: true,
		},
		height: {
			type: Number,
			default: 40,
		},
		// 键盘选中
		activeMember: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
};
</script>

<style lang="scss">
.chat-group-member {
	display: flex;
	position: relative;
	align-items: center;
	white-space: nowrap;
	box-sizing: border-box;
	padding: 0 4px;
	margin: 0 4px;
	overflow: hidden;
	&:hover {
		background: #f5f5f5;
	}

	&.active {
		background: #f5f5f5;
	}

	.avatar {
		width: 28px;
		height: 28px;
		border-radius: 4px;
	}

	.member-name {
		padding-left: 6px;
		height: 100%;
		white-space: nowrap;
		overflow: hidden;
		font-size: 14px;
		color: #3d3d3d;
	}
}
</style>
