var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"layout"},[_c('el-header',{attrs:{"height":"72px"}},[_c('q-header')],1),_c('el-container',{staticStyle:{"overflow":"auto"}},[_c('backTop',{on:{"gotoTop":_vm.gotoTop}}),_c('el-aside',{attrs:{"width":"180px"}},[_c('q-aside')],1),_c('el-main',{staticStyle:{"background-color":"#f8f8fc"}},[_c('div',{staticClass:"main-content",staticStyle:{"height":"100%","overflow":"auto"},on:{"scroll":_vm.handleScroll}},[_c('KeepAlive',{attrs:{"include":[
						'home',
						'shortPlay',
						'imageTextList',
						'questionList',
						'videoList',
					]}},[_c('router-view')],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }