<template>
	<div class="NewFriends_card">
		<div v-for="item of list" :key="item.id" class="user_row">
			<div class="userData">
				<div class="avatar">
					<el-avatar :src="item.userAvatar" ></el-avatar>
					<div class="point" v-if="!item.readState"></div>
				</div>
				<div class="userInfo">
					<div class="content">
						<div class="name">{{ item.userName }}</div>
						<div class="apply">{{ item.applyMsg }}</div>
					</div>
					<div class="time">{{ item.createTime }}</div>
				</div>
			</div>
			<div class="rightBtn">
				<template v-if="item.applyState == 'audit'">
					<button v-if="item.active == 'N'" @click="agreeClick(item)">
						同意
					</button>
					<span class="info" v-else>
						<i class="icon-sohu-youshangjiantou"></i>
						等待对方审核
					</span>
				</template>
				<span v-else class="info">
					<i v-if="item.active == 'Y'" class="icon-sohu-youshangjiantou"></i>
					{{ stateFilter(item.applyState) }}
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { sendWSPush } from '@/utils/websocket';
export default {
	props: ['list'],
	data() {
		return {};
	},
	methods: {
		// 同意按钮
		async agreeClick(val) {
			const res = await this.$http.appFriendsPass({
				// id: val.id,
				...val
			});
			if (res.code == 200) {
				this.$message.success('操作成功');
				val.applyState = 'pass';
				sendWSPush({
					localId: this.$util.getUuid(),
					receiverId: val.friendId,
					sessionType: 'single',
					// messageType: 'command',
					// commandType: 'addFriendPass',
					messageType: 'text',
					content: `我已同意你的好友申请`,
				});
			}
			console.log(res);
		},

		// 状态过滤
		stateFilter(val) {
			const obj = {
				timeOut: '已过期',
				pass: '已添加',
				audit: '审核中',
				reject: '拒绝',
			};
			return val && obj[val];
		},
	},
};
</script>
<style lang="scss">
.NewFriends_card {
	background-color: #fff;
	border-radius: 8px;
	padding: 24px;
	box-sizing: border-box;
	display: grid;
	row-gap: 20px;
	.user_row {
		display: flex;
		justify-content: space-between;
		.userData {
			display: flex;
      align-items: center;
      .avatar {
        position: relative;
        .point {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #FF6C27;
          position: absolute;
          top: calc(50% - 4px);
          left: -15px;
        }
        .el-avatar {
          width: 48px;
          height: 48px;
          margin-right: 11px;
        }
      }
			.userInfo {
				.content {
          display: flex;
					align-items: center;
          .name {
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 2px;
            line-height: 22px;
            max-width: 180px;
						margin-right: 11px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
          }
					.apply {
						font-size: 12px;
						color: #999999;
						line-height: 18px;
					}
				}
				.applyMsg {
					color: #999999;
					line-height: 18px;
					font-size: 12px;
					margin-bottom: 2px;
				}
				.time {
					color: #999999;
					font-size: 12px;
					line-height: 18px;
				}
			}
		}
		.rightBtn {
			button {
				background: #f2f2f2;
				height: 24px;
				width: 64px;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #333333;
				font-weight: 500;
				font-size: 12px;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
				&.active {
					background-color: $theme-color;
					color: #fff;
				}
			}
			.info {
				color: #707378;
				font-size: 12px;
				line-height: 21px;
				display: flex;
				align-items: center;
				i {
					font-size: 18px;
					margin-right: 1px;
				}
			}
		}
	}
}
</style>
