<template>
	<div v-infinite-scroll="loadMore">
		<div class="comment-main">
			<p class="comment-num" v-if="type !== 'Video'">
				共{{ commentTotal }}条评论
			</p>
			<div
				class="comment-item"
				v-for="(item, index) in commentList"
				:key="index"
			>
				<div class="head-main" @click="goMyDetail(item)">
					<img :src="item.commentUserAvatar" class="master-head" alt="" />
				</div>
				<div class="comment-content">
					<div class="nickname-box">
						<p class="nickname line-1">
							{{ item.commentUserName
							}}<span
								class="author"
								v-if="detailInfo.userId == item.commentUser"
								>作者</span
							>
						</p>
					</div>
					<div class="content-main">
						<p class="content">{{ item.content }}</p>
						<div
							class="praise-main"
							@click="handleOpt('like', item, 'Comment')"
						>
							<i class="icon-sohu-love" v-show="!item.praiseObj"></i
							><i
								class="icon-sohu-xihuan"
								:class="{ likeActive: item.praiseObj }"
								v-show="item.praiseObj"
							></i>
							<p>{{ item.praiseCount }}</p>
						</div>
					</div>
					<p class="time">
						{{ item.commentTime }} {{ item.ipAddress }}
						<span class="reply" @click="handleReply(item, index)">回复</span>
					</p>
					<template>
						<div
							style="margin-top: 12px"
							class="comment-item"
							v-for="(it, i) in commentList[index].replyList"
						>
							<div class="head-main" @click="goMyDetail(it)">
								<img :src="it.commentUserAvatar" class="master-head" alt="" />
							</div>
							<div class="comment-content">
								<div class="nickname-box">
									<p class="nickname line-1">
										{{ it.commentUserName
										}}<span
											class="author"
											v-if="detailInfo.userId == it.commentUser"
											>作者</span
										>
									</p>
								</div>
								<div class="content-main">
									<p class="content">{{ it.content }}</p>
									<div
										class="praise-main"
										@click="handleOpt('like', it, 'Comment')"
									>
										<i class="icon-sohu-love" v-show="!it.praiseObj"></i
										><i
											class="icon-sohu-xihuan"
											:class="{ likeActive: it.praiseObj }"
											v-show="it.praiseObj"
										></i>
										<p>{{ it.praiseCount }}</p>
									</div>
								</div>
								<p class="time">
									{{ it.commentTime }} {{ it.ipAddress }}
									<span class="reply" @click="handleReply(it, index)"
										>回复</span
									>
								</p>
							</div>
						</div>
					</template>
					<div class="expand" v-if="item.lastCommentId">
						<span
							class="text"
							v-if="
								!item.expand ||
								(commentList[index].replyList.length &&
									commentList[index].replyList.length !==
										commentList[index].replyTotal)
							"
							@click="loadReply(item, index)"
						>
							展开更多回复
						</span>
						<span
							class="text"
							v-if="commentList[index].replyList.length !== 0"
							@click="replyClose(item, index)"
							>收起</span
						>
					</div>
				</div>
			</div>
			<q-empty
				content="暂无评论，快去抢占沙发吧~"
				v-if="!commentList.length && type != 'Answer'"
				:height="height"
			></q-empty>
			<div :class="{ 'image-text-send': type == 'Article' }">
				<div class="send-main">
					<el-input
						:placeholder="
							Object.keys(commentItem).length > 0
								? '回复@' + commentItem.commentUserName
								: '说点什么吧～'
						"
						v-model="sendMsg"
						@keyup.enter.native="handleOpt('send')"
					></el-input>
					<p
						class="send"
						@click="handleOpt('send')"
						:class="{ 'video-send': type == 'Video' }"
					>
						发送
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	aiBuryContentType,
	aiBuryEvent,
	aiBuryScene,
} from '@/utils/filter/aiModelEnum';

export default {
	props: {
		//内容id
		id: {
			type: [Number, String],
		},
		//评论类型
		type: {
			type: String,
			default() {
				return 'Video';
			},
		},
		value: {
			type: Number,
			default() {
				return 0;
			},
		},
		height: {
			type: String,
		},
		//详情
		detailInfo: {
			type: Object,
		},
		//入口位置
		entry: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			commentList: [], //评论列表
			commentTotal: 0, //总评论数
			firstStageTotal: 0, //第一级评论数
			commentItem: {}, //当前选中评论的信息
			sendMsg: '',
			presentNum: 0,
			commentPageNum: 1,
			mainLoading: false,
		};
	},
	watch:{
		detailInfo(newval){
			this.getCommentList();
		}
	},
	methods: {
		loadMore() {
			if (!this.mainLoading && this.type != 'Answer') {
				if (this.firstStageTotal > this.commentList.length) {
					this.commentPageNum++;
					this.getCommentList();
				}
			}
		},
		// 获取评论列表
		async getCommentList(val) {
			this.commentTotal = this.value;
			if (this.mainLoading) return;
			this.mainLoading = true;
			const res = await this.$http.getCommentList({
				busyCode: val ? val.id : this.id,
				busyType: this.type,
				pageNum: this.commentPageNum,
				pageSize: this.type == 'Answer' ? 1000 : 10,
			});
			res.data.forEach((item) => {
				item.num = 1;
				item.replyTotal = 0;
				item.expand = false;
				item.replyList = [];
			});
			if (this.commentPageNum == 1) {
				this.commentList = res.data;
			} else {
				this.commentList = this.commentList.concat(res.data);
			}
			this.firstStageTotal = res.total;
			this.mainLoading = false;
		},
		// 进行各种处理操作
		async handleOpt(type, obj, m) {
			switch (type) {
				case 'like': //点赞
					this.handlePraise(obj, m);
					break;
				case 'send':
					if (!this.sendMsg) {
						this.$message.error('内容不能为空');
					} else {
						this.$login().then(async (res) => {
							//发布评论
							let params = {};
							if (Object.keys(this.commentItem).length == 0) {
								params = {
									busyCode: this.id,
									content: this.sendMsg,
									busyType: this.type,
									busyUser: this.detailInfo.userId,
									siteId: this.$store.state.siteId,
								};
							} else {
								params = {
									busyCode: this.id,
									content: this.sendMsg,
									busyType: this.type,
									busyUser: this.detailInfo.userId,
									replyUser: this.commentItem.replyUser,
									pid: this.commentItem.pid,
									siteId: this.$store.state.siteId,
									replyId: this.commentItem.replyId
								};
							}
							let commentRes = await this.$http.commentPost(params);
							if (commentRes.code == 200) {
								this.commentPageNum = 1;
								Object.keys(this.commentItem).length == 0
									? await this.getCommentList()
									: await this.firstComment();
								this.commentTotal++;
								this.$emit('input', this.commentTotal);
								this.sendMsg = '';
								this.commentItem = {};
								//AI智能模型评论埋点
								this.aiRecContentFilter();
							}
						});
					}

					break;
			}
		},
		//展开更多评论
		async loadReply(item, i) {
			const res = await this.$http.getCommentList({
				pageNum: this.commentList[i].num,
				pageSize: 10,
				busyType: this.type,
				busyCode: this.id,
				pid: item.id,
			});
			this.commentList[i].replyTotal = res.total;
			if (this.commentList[i].replyList.length < res.total) {
				this.commentList[i].num = this.commentList[i].num + 1; //页数+1
				this.commentList[i].expand = true;
			} else {
				this.commentList[i].expand = false;
			}
			this.commentList[i].replyList.push(...res.data);
			// if (this.comment[i].replyList.length == res.total)
			// 	this.comment[i].expand = true;
		},
		// 评论的展开与收起
		replyClose(item, i) {
			this.commentList[i].num = 1; //页数+1
			this.commentList[i].replyList = [];
			this.commentList[i].expand = false;
		},
		// 点击回复
		handleReply(item, index) {
			console.log(item,'回复');
			
			this.commentItem = item;
			this.presentNum = index;
			if (item.pid == 0) {
				this.commentItem.pid = item.pid == 0 ? item.id : item.pid;
			}
			this.commentItem.replyUser = item.commentUser;
			this.commentItem.replyId = item.id;
		},
		// 点赞
		async handlePraise(obj, type) {
			const res = await this.$http.contentMainLike({
				busyCode: obj.id,
				busyType: type,
			});
			if (res.code === 200) {
				if (res.data) {
					obj.praiseObj = true;
					obj.praiseCount = obj.praiseCount + 1;
					this.$message.success('点赞成功');
				} else {
					obj.praiseObj = false;
					obj.praiseCount = obj.praiseCount - 1;
				}
			}
		},
		//回复评论完成后查看第一页评论
		async firstComment() {
			this.commentList[this.presentNum].num = 1;
			const comment = await this.$http.getCommentList({
				pageNum: this.commentList[this.presentNum].num,
				pageSize: 10,
				busyType: this.type,
				busyCode: this.id,
				pid: this.commentItem.pid,
			});
			this.commentList[this.presentNum].replyTotal = comment.total;
			if (this.commentList[this.presentNum].replyList.length <= comment.total) {
				this.commentList[this.presentNum].num =
					this.commentList[this.presentNum].num + 1; //页数+1
				// this.comment[this.presentNum].hasReply = false;
			} else {
				// this.comment[this.presentNum].hasReply = true;
				// this.comment[this.presentNum].num = this.comment[this.presentNum].num + 1; //页数+1
			}
			this.commentList[this.presentNum].expand = true;
			this.commentList[this.presentNum].replyList = comment.data;
		},
		// 查看别人视角我的详情
		goMyDetail(val) {
			// 如果是本人，跳转我的页面
			if (this.$store.state.userInfo.id == val.commentUser) {
				this.$login().then((res) => {
					this.$router.push({
						path: '/my',
					});
				});
			} else {
				this.$router.push({
					path: '/othersHome',
					query: {
						userId: val.commentUser,
					},
				});
			}
		},
		/**
		 * 内容埋点过滤器
		 */
		aiRecContentFilter() {
			let type = this.$props.type;
			console.log(type);
			switch (type) {
				case 'Video':
					this.videoSceneFilter();
					break;
				case 'Article':
					this.articleSceneFilter();
					break;
			}
		},
		/**
		 * 图文埋点场景过滤器
		 */
		articleSceneFilter() {
			if (this.entry === '') return;
			let scene = ''; //场景
			switch (this.entry) {
				case 'home':
					scene = aiBuryScene.homeArticle;
					break;
				case 'money':
					scene = aiBuryScene.moneyArticle;
					break;
				default:
					scene = aiBuryScene.homeArticle;
					break;
			}
			this.handleAiRec(
				scene,
				aiBuryContentType.article,
				aiBuryEvent.comment,
				JSON.parse(localStorage.getItem('article_aiResultItem')),
			);
		},
		/**
		 * 视频埋点场景过滤器
		 */
		videoSceneFilter() {
			if (this.entry === '') return;
			let scene = ''; //场景
			let content = aiBuryContentType.video; //场景预留参数
			switch (this.entry) {
				case 'home':
					scene = aiBuryScene.homeVideo;
					break;
				case 'money':
					scene = aiBuryScene.moneyVideo;
					break;
				default:
					scene = aiBuryScene.homeVideo;
					break;
			}
			this.handleAiRec(
				scene,
				content,
				aiBuryEvent.comment,
				this.$props.detailInfo.aiResultItem,
			);
		},
		/**
		 *  AI智能模型
		 * @param scene 场景
		 * @param content 内容
		 * @param event 事件
		 * @param data AI埋点数据
		 */
		handleAiRec(scene, content, event, data) {
			if (this.entry === '') return;
			if (data) {
				this.$http.aiRecAdd(scene, content, event, {
					...data,
					bhvValue: 1,
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.comment-main {
	padding: 20px 32px 62px;
	border-top: 1px solid #e7e7e7;
	.comment-num {
		font-size: 19.5px;
		font-weight: 500;
		color: #333;
		margin: 28px 0 30px;
	}

	.likeActive {
		color: $like-color !important;
	}

	.collectActive {
		color: $collect-color !important;
	}

	.comment-item {
		display: flex;
		gap: 12px;
		margin-bottom: 20px;
		.head-main {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			cursor: pointer;
			.master-head {
				width: 100%;
				height: 100%;
			}
		}

		.child-head {
			width: 24px;
			height: 24px;
			border-radius: 50%;
		}
		.comment-content {
			flex: 1;
			.nickname-box {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.author {
				display: inline-block;
				text-align: center;
				line-height: 20px;
				width: 40px;
				height: 20px;
				border-radius: 4px;
				background: $theme-color;
				font-size: 12px;
				color: #fff;
				margin-left: 16px;
			}

			.nickname {
				font-size: 14px;
				font-weight: 500;
				color: #3d3d3d;
			}

			.content-main {
				display: flex;
				justify-content: space-between;
				gap: 5px;
				align-items: flex-start;
				margin-top: 10px;
				.content {
					font-size: 16px;
					font-weight: 400;
					color: #3d3d3d;
					flex: 1;
					word-break: break-all;
				}
				.praise-main {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 5px;
					margin-top: 5px;
					width: 30px;
					justify-content: flex-end;
				}
			}

			.time {
				font-size: 14px;
				color: #999;
				margin: 5px 0;
			}

			.reply {
				font-size: 14px;
				font-weight: 500;
				color: #333;
				margin-left: 10px;
				cursor: pointer;
			}
		}
	}

	.expand {
		margin-bottom: 10px;
		margin-top: 10px;
		font-size: 14px;
		font-weight: 500;
		color: #366ef4;
		cursor: pointer;
	}
}

.send-main {
	display: flex;
	padding: 0 20px;
	align-items: center;
	gap: 20px;
	// position: absolute;
	height: 82px;
	bottom: 0;
	left: 0;
	background: #fff;
	width: calc(100% - 40px);
	z-index: 1000;
	:deep(.el-input) {
		flex: 1;
	}

	:deep(.el-input__inner) {
		background: #fafafa !important;
		border-radius: 8px !important;
	}

	.send {
		cursor: pointer;
		font-size: 14px;
		font-weight: 500;
		color: #fff;
		width: 102px;
		height: 46px;
		text-align: center;
		line-height: 46px;
		background: $theme-color;
		border-radius: 142px;
	}
	.video-send {
		text-align: center;
		line-height: 46px;
		background: #fff;
		color: #999;
		width: 46px;
	}
}
.image-text-send {
	position: fixed;
	bottom: 60px;
	padding: 0;
	width: 40%;
	right: 10%;
	border-radius: 142px;
	:deep(.el-input__inner) {
		border-radius: 142px !important;
	}
}
</style>
