<template>
	<dialogBox
		appendToBody
		:showHeader="false"
		v-model="loginDialogShow"
		width="488px"
		class="loginDialog"
	>
		<div class="loginBox">
			<!-- 标题 -->
			<div class="loginHeard">
				<div class="welcomeMessage" v-if="accountState === 'login'">
					<span class="welcome">欢迎登录狐少少</span>
					<span class="hint">登录后解锁更多功能！</span>
				</div>
				<div class="resetPasswords" v-if="accountState === 'pwd'">重置密码</div>
				<i @click="cancelClick" class="icon-sohu-quxiao"></i>
			</div>
			<div :class="['loginMain', { resetPwd: accountState === 'pwd' }]">
				<div v-if="accountState === 'login'">
					<!-- tab切换 -->
					<div
						class="tabs"
						v-if="['userName', 'phone', 'AppScan'].includes(loginActive)"
					>
						<div
							:class="['tab_item', { active: item.value == loginActive }]"
							v-for="item of tabs"
							:key="item.value"
							@click="tabsChange(item.value)"
						>
							{{ item.name }}
						</div>
					</div>
					<!-- App扫码 -->
					<div class="scanBox appQrCode" v-if="loginActive == 'AppScan'">
						<div class="QrCode">
							<!-- <img
								class="img"
								src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/08/21/5e69ef70c1434363a72836152d72e8dd_320x320.png"
							/> -->
							<vue-qr :margin="0" :text="qrCodeIdVal" :size="176"></vue-qr>

							<img
								class="overdueBox"
								v-if="overdueState"
								@click="overdueClick"
								src="../../assets/images/overdueQr.png"
							/>
						</div>
						<!-- 狐少少提示语 -->
						<div class="hint">
							打开狐少少App在首页右上角
							<i class="el-icon-circle-plus-outline"></i>中使用
							<span>“扫一扫”</span>功能
						</div>
					</div>
					<!-- 微信扫码 -->
					<div class="scanBox" v-if="loginActive == 'WeChatScan'">
						<!-- <div class="WeChatHint">
							<div>微信扫码登录</div>
							<div class="gray">未注册的微信号将自动注册狐少少账号</div>
						</div> -->

						<!-- <div class="QrCode">
							<img
								class="img appImg"
								src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/08/21/5e69ef70c1434363a72836152d72e8dd_320x320.png"
							/>
							
							<vue-qr :margin="0" :text="wenXinPage" :size="159"></vue-qr>
							<div class="describe">
								<img
									src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/08/22/ead96dd11cee43ad88cd0cb9014fb3d1_56x46.png"
								/>
								微信扫码
							</div>
						</div> -->
						<iframe
							class="iframe"
							sandbox="allow-scripts allow-top-navigation allow-same-origin"
							seamless
							height="400px"
							:src="wenXinPage"
						></iframe>
					</div>
					<!-- 手机号账号密码 -->
					<div
						class="userNameBox"
						v-if="['userName', 'phone'].includes(loginActive)"
					>
						<el-form ref="loginRef" :model="loginForm" :rules="loginRules">
							<el-form-item prop="userName">
								<el-input
									v-model="loginForm.userName"
									:placeholder="
										loginActive == 'userName' ? '请输入账号' : '请输入手机号'
									"
									maxLength="11"
								/>
							</el-form-item>
							<!-- 密码 -->
							<el-form-item prop="password" v-if="loginActive == 'userName'">
								<el-input
									show-password
									type="password"
									v-model="loginForm.password"
									placeholder="请输入密码"
								/>
							</el-form-item>
							<el-form-item prop="code" v-if="loginActive == 'phone'">
								<div class="getCode">
									<el-input
										v-model="loginForm.code"
										placeholder="请输入验证码"
										type="text"
										maxLength="4"
									/>
									<el-button
										class="getCodeBtn"
										:disabled="disableGetCodeBtn || time > 0"
										color="#016BFF"
										@click="getCodeClick"
										>{{ time <= 0 ? '发送验证码' : time + 's' }}
									</el-button>
								</div>
							</el-form-item>
						</el-form>
						<div class="bottomError">
							<span
								class="forgotPassword"
								v-if="accountState == 'login' && loginActive == 'userName'"
								@click="forgotPasswordClick"
								>忘记密码</span
							>
						</div>
					</div>
					<!-- 微信首次扫码绑定手机号 -->
					<div
						class="WxBindingPhoneBox"
						v-if="['bindingPhone'].includes(loginActive)"
					>
						<div class="WxBindingPhoneTitle">绑定手机号</div>
						<div class="WxBindingPhoneHint">
							未注册的微信号将自动注册狐少少账号
						</div>
						<el-form
							ref="bindingPhoneRef"
							:model="bindingPhoneForm"
							:rules="bindingPhoneRules"
						>
							<el-form-item prop="mobile">
								<el-input
									v-model="bindingPhoneForm.mobile"
									placeholder="请输入手机号"
									maxLength="11"
								/>
							</el-form-item>
							<el-form-item prop="code">
								<div class="getCode">
									<el-input
										v-model="bindingPhoneForm.code"
										placeholder="请输入验证码"
										type="text"
										maxLength="4"
									/>
									<el-button
										class="getCodeBtn"
										:disabled="disableGetCodeBtn || time > 0"
										color="#016BFF"
										@click="getCodeClick"
										>{{ time <= 0 ? '发送验证码' : time + 's' }}
									</el-button>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<!-- 重置密码 -->
				<div class="resetPwdBox" v-if="accountState === 'pwd'">
					<el-form ref="resetPwdFormRef" :model="pwdForm" :rules="pwdRules">
						<div class="phoneBox">
							<div class="typeText">+86</div>
							<el-form-item prop="phone">
								<el-input
									v-model="pwdForm.phone"
									placeholder="手机号"
									maxLength="11"
								/>
							</el-form-item>
						</div>
						<el-form-item prop="newPwd">
							<el-input
								show-password
								type="password"
								v-model="pwdForm.newPwd"
								placeholder="新密码"
								maxLength="11"
							/>
						</el-form-item>
						<el-form-item prop="affirmPwd">
							<el-input
								show-password
								type="password"
								v-model="pwdForm.affirmPwd"
								placeholder="密码确认"
								maxLength="11"
							/>
						</el-form-item>
						<el-form-item prop="code">
							<div class="getCode">
								<el-input
									v-model="pwdForm.code"
									placeholder="请输入验证码"
									type="text"
									maxLength="4"
								/>
								<el-button
									class="getCodeBtn"
									:disabled="disableGetCodeBtn || time > 0"
									color="#016BFF"
									@click="getCodeClick"
									>{{ time <= 0 ? '发送验证码' : time + 's' }}
								</el-button>
							</div>
						</el-form-item>
					</el-form>
					<div
						class="bottomRadio"
						style="margin-bottom: 8px"
						v-if="accountState === 'pwd'"
					>
						<i
							@click="radioValue = !radioValue"
							:class="{
								'icon-sohu-danxuanweixuanzhong': !radioValue,
								'icon-sohu-xuanzhong': radioValue,
							}"
						></i>
						<div class="service">
							我阅读并同意
							<span @click="serviceClick(1)">《用户协议》</span>和<span
								@click="serviceClick(2)"
								>《隐私政策》</span
							>
						</div>
					</div>
				</div>
				<!-- 按钮 -->
				<el-button
					v-if="
						['phone', 'userName'].includes(loginActive) ||
						accountState === 'pwd'
					"
					:disabled="disableLoginBtn"
					class="loginBtn"
					@click="loginClick"
					color="#016BFF"
					>{{
						accountState === 'login'
							? loginActive === 'userName'
								? '登录'
								: '登录/注册'
							: '确定'
					}}
				</el-button>
				<!-- 微信绑定手机号按钮 -->
				<el-button
					v-if="['bindingPhone'].includes(loginActive)"
					:disabled="bindingPhoneBtn"
					class="loginBtn"
					@click="bindingPhoneClick"
					color="#016BFF"
					>同意协议并绑定
				</el-button>
				<!-- 密码返回登录 -->
				<div
					class="getBack"
					v-if="accountState === 'pwd'"
					@click="getBackClick"
				>
					返回登录
				</div>
				<!-- 协议 -->
				<div
					class="bottomRadio"
					v-if="
						accountState === 'login' &&
						['userName', 'phone', 'bindingPhone'].includes(loginActive)
					"
				>
					<i
						@click="radioValue = !radioValue"
						:class="{
							'icon-sohu-danxuanweixuanzhong': !radioValue,
							'icon-sohu-xuanzhong': radioValue,
						}"
					></i>
					<div class="service">
						我已阅读并同意
						<span @click="serviceClick(1)">《用户协议》</span>和<span
							@click="serviceClick(2)"
							>《隐私政策》</span
						>
					</div>
				</div>
				<!-- 第三方登录 -->
				<div class="otherLogin" v-if="accountState == 'login'">
					<div class="otherLoginText">
						<div class="bit"></div>
						<span>{{
							['userName', 'phone', 'AppScan'].includes(loginActive)
								? '第三方登录'
								: '狐少少登录'
						}}</span>
						<div class="bit"></div>
					</div>
					<img
						class="WeChat"
						@click="WeChatClick"
						:src="
							['userName', 'phone', 'AppScan'].includes(loginActive)
								? WeChatIcon
								: loginIcon
						"
					/>
				</div>
			</div>
		</div>
	</dialogBox>
</template>
<script>
import VueQr from 'vue-qr';
import dialogBox from '@/components/dialogBox.vue';
import store from '@/store';
import router from '@/router';
import URL from '@/utils/baseUrl';
export default {
	components: { dialogBox, VueQr },
	data() {
		var validatePass1 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.pwdForm.affirmPwd !== '') {
					this.$refs.resetPwdFormRef.validateField('affirmPwd');
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.pwdForm.newPwd) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		var validateLogin = (rule, value, callback) => {
			if (value === '') {
				if (this.loginActive === 'phone') {
					callback(new Error('请输入手机号'));
				} else {
					callback(new Error('请输入账号'));
				}
			} else if (
				this.loginActive === 'phone' &&
				!this.phoneReg.test(this.loginForm.userName)
			) {
				callback('请输入正确的手机号');
			} else {
				callback();
			}
		};
		var validateMobile = (rule, value, callback) => {
			if (value === '' || value === null) {
				callback(new Error('请输入手机号'));
			} else if (!this.phoneReg.test(value)) {
				callback('请输入正确的手机号');
			} else {
				callback();
			}
		};
		return {
			bindToken: '', //token
			uuid: '', //uuid
			qrCodeId: '', //app二维码标识
			qrCodeIdVal: '',
			wenXinPage: '',
			WeChatIcon: require('@/assets/images/WeChatIcon.png'),
			loginIcon: require('@/assets/images/loginIcon.png'),
			tabs: [
				{
					name: '扫码登录',
					value: 'AppScan',
				},
				{
					name: '验证码登录',
					value: 'phone',
				},
				{
					name: '密码登录',
					value: 'userName',
				},
			],
			loginActive: 'phone',
			// loginActive: 'bindingPhone',
			radioValue: false,
			// 密码登录
			loginForm: {
				userName: '',
				password: '',
				code: '',
			},
			//微信绑定手机号
			bindingPhoneForm: {
				mobile: '', //手机号
				code: '', //验证码
			},
			bindingPhoneRules: {
				mobile: [
					{ required: true, validator: validateMobile, trigger: 'blur' },
				],
				code: [{ required: true, trigger: 'blur', message: '请输入验证码' }],
			},
			loginRules: {
				userName: [
					{ required: true, validator: validateLogin, trigger: 'blur' },
				],
				password: [
					{ required: true, trigger: 'blur', message: '请输入登录密码' },
				],
				code: [{ required: true, trigger: 'blur', message: '请输入验证码' }],
			},
			//重置密码
			pwdForm: {
				phone: '',
				newPwd: '',
				affirmPwd: '',
				code: '',
			},
			pwdRules: {
				phone: [{ required: true, trigger: 'blur', message: '请输入手机号' }],
				newPwd: [{ required: true, validator: validatePass1, trigger: 'blur' }],
				affirmPwd: [
					{ required: true, validator: validatePass2, trigger: 'blur' },
				],
				code: [{ required: true, trigger: 'blur', message: '请输入验证码' }],
			},
			loginError: false,
			time: 0,
			accountState: 'login', //登录：login  密码：pwd
			fromInvite: false, // 是否来自邀请连接
			agree: false, // 是否勾选同意条款
			bizType: '',
			bizCode: '',
			roleKey: '',
			mcnData: {},
			timer: null,
			phoneReg: /^[1][3-9][0-9]{9}$/,
			loginDialogShow: false,
			lastTimeActive: 'userName', //切换扫码最后选择
			overdueState: false, //app扫码登录码是否过期
		};
	},
	computed: {
		disableLoginBtn() {
			if (this.accountState === 'login') {
				// 登录
				if (this.loginActive == 'phone') {
					if (
						this.phoneReg.test(this.loginForm.userName) &&
						this.loginForm.code
					) {
						return false;
					} else {
						return true;
					}
				} else {
					if (this.loginForm.userName && this.loginForm.password) {
						return false;
					} else {
						return true;
					}
				}
			} else {
				if (
					this.phoneReg.test(this.pwdForm.phone) &&
					this.pwdForm.code &&
					this.pwdForm.newPwd &&
					this.pwdForm.affirmPwd &&
					this.pwdForm.newPwd === this.pwdForm.affirmPwd
				) {
					return false;
				} else {
					return true;
				}
			}
		},
		// 获取验证码
		disableGetCodeBtn() {
			if (
				this.phoneReg.test(this.loginForm.userName) ||
				this.phoneReg.test(this.pwdForm.phone) ||
				this.phoneReg.test(this.bindingPhoneForm.mobile)
			) {
				return false;
			} else {
				return true;
			}
		},
		// 微信绑定手机号
		bindingPhoneBtn() {
			if (
				this.phoneReg.test(this.bindingPhoneForm.mobile) &&
				this.bindingPhoneForm.code
			) {
				return false;
			} else {
				return true;
			}
		},
	},
	mounted() {
		// 绑定
		if (this.uuid) {
			this.loginActive = 'bindingPhone';
		}
		this.getUniqueValue();
	},
	methods: {
		// 切换登录方式
		tabsChange(val) {
			if (
				['userName', 'phone'].includes(this.loginActive) &&
				this.loginActive != val
			) {
				this.$refs.loginRef.clearValidate();
			}
			this.loginActive = val;

			if (this.loginActive === 'AppScan') {
				this.getLoginInfoQrCodeId();
			}
		},
		// 获取验证码
		async getCodeClick() {
			this.time = 60;
			let phoneNum = '';
			if (this.accountState === 'login') {
				phoneNum = this.loginForm.userName;
			} else {
				phoneNum = this.pwdForm.phone;
			}
			if (this.loginActive == 'bindingPhone') {
				phoneNum = this.bindingPhoneForm.mobile;
			}
			let res = await this.$http.getCode({
				phone: phoneNum,
				bizType: 'login',
			});
			if (res.code == 200) {
				let timer = setInterval(() => {
					this.time--;
					if (this.time <= 0) {
						clearInterval(timer);
					}
				}, 1000);
			} else if (res.code == 500) {
				this.time = 0;
				this.$message.error('短信发送过于频繁');
			}
		},
		//忘记密码
		forgotPasswordClick() {
			this.$refs.loginRef.resetFields();
			this.accountState = 'pwd';
			this.time = 0;
		},
		//返回登录
		getBackClick() {
			this.$refs.resetPwdFormRef.resetFields();
			this.accountState = 'login';
			this.time = 0;
		},
		//取消
		cancelClick() {
			if (
				this.accountState === 'login' &&
				['userName', 'phone'].includes(this.loginActive)
			) {
				this.$refs.loginRef.resetFields();
			} else if (this.accountState === 'pwd') {
				this.$refs.resetPwdFormRef.resetFields();
			}
			this.accountState = 'login';
			this.time = 0;
			this.loginDialogShow = false;
		},
		//扫码切换
		WeChatClick() {
			if (['phone', 'userName', 'AppScan'].includes(this.loginActive)) {
				this.lastTimeActive = this.loginActive;
				this.loginActive = 'WeChatScan';
				if (this.loginActive === 'WeChatScan') {
					this.wenXinPage =
						'https://open.weixin.qq.com/connect/qrconnect?' +
						'appid=' +
						'wxfa1d31fae3efa865' +
						'&' +
						'redirect_uri=' +
						encodeURIComponent('http://www.sohuglobal.com/home') +
						'&' +
						'scope=snsapi_login#wechat_redirect';
				}
			} else {
				this.loginActive = this.lastTimeActive;
			}
			if (this.loginActive === 'AppScan') {
				this.getLoginInfoQrCodeId();
			}
		},

		// 点击服务条款
		serviceClick(val) {
			let url = '';
			if (val == 1) {
				url = 'https://world.sohuglobal.com/agreementOfUser.html';
			}
			if (val == 2) {
				url = 'https://world.sohuglobal.com/agreementOfPrivacy.html';
			}
			window.open(url);
		},
		// 登录及修改密码按钮
		async loginClick() {
			if (!this.radioValue)
				return this.$message.error('请勾选服务条款和隐私协议');

			if (this.accountState === 'login') {
				if (this.loginActive == 'phone') {
					//手机号验证码
					this.$refs.loginRef.validate(async (valid) => {
						let that = this;
						if (valid) {
							let res = await this.$http.authLogin({
								loginType: 'SMS_CODE',
								phoneNumber: this.loginForm.userName,
								smsCode: this.loginForm.code,
								deviceType: 'PC',
							});
							if (res.code === 200) {
								this.$message.success('登录成功');
								that.cancelClick();
								store.commit('setToken', res.data.accessToken);
								await store.dispatch('init');
								this.callback('confirm');
							}
						}
					});
				} else {
					//账号密码
					this.$refs.loginRef.validate(async (valid) => {
						let that = this;
						if (valid) {
							let res = await this.$http.authLogin({
								loginType: 'PASSWORD',
								userName: this.loginForm.userName,
								deviceType: 'PC',
								password: this.$util.encrypt(this.loginForm.password),
							});
							if (res.code === 200) {
								this.$message.success('登录成功');
								that.cancelClick();
								store.commit('setToken', res.data.accessToken);
								await store.dispatch('init');
								this.callback('confirm');
							}
						}
					});
				}
			} else {
				this.$refs.resetPwdFormRef.validate(async (valid) => {
					let that = this;
					if (valid) {
						let res = await this.$http.resetPwd({
							phone: this.pwdForm.phone,
							password: this.pwdForm.newPwd,
							code: this.pwdForm.code,
						});
						if (res.code === 200) {
							this.$message.success('设置成功');
							this.$refs.resetPwdFormRef.resetFields();
							that.accountState = 'login';
						}
					}
				});
			}
		},
		// 微信扫码绑定
		async bindingPhoneClick() {
			let res = await this.$http.authThirdAppLogin({
				socialType: 'wechat_qr',
				uuid: this.uuid,
				mobile: this.bindingPhoneForm.mobile,
				code: this.bindingPhoneForm.code,
			});
			if (res.code === 200) {
				this.$store.commit('setToken', res.data.accessToken);
				window.localStorage.setItem('token', res.data.accessToken);
				this.$refs.bindingPhoneRef.resetFields();
				this.cancelClick();
				router.push({
					path: '/home',
				});
				window.location.reload();
			}
		},
		// 获取app扫码唯一值
		async getUniqueValue() {
			let res = await this.$http.getQrCodeId();
			if (res.code === 200) {
				this.qrCodeId = res.data.qrCodeId;
				this.qrCodeIdVal = `${window.location.origin}?qrCodeId=${res.data.qrCodeId}&type=login`;
				this.overdueState = false;
			}
		},
		// 狐少少扫码
		async getLoginInfoQrCodeId() {
			let timer1 = setInterval(() => {
				this.$http.loginInfoQrCodeId(this.qrCodeId).then((res) => {
					// 二维码200过期
					if (res.msg == 'QR code invalid') {
						this.overdueState = true;
						clearInterval(timer1);
					}
					if (
						this.loginActive != 'AppScan' ||
						res.data?.accessToken ||
						!this.loginDialogShow
					) {
						clearInterval(timer1);
						if (res.data?.accessToken) {
							this.$store.commit('setToken', res.data.accessToken);
							window.localStorage.setItem('token', res.data.accessToken);
							window.location.reload();
						}
					}
				});
			}, 1000);
		},
		//二维码过期
		async overdueClick() {
			await this.getUniqueValue();
			await this.getLoginInfoQrCodeId();
		},
	},
};
</script>
<style lang="scss">
.loginBox {
	border-radius: 24px;
	padding: 26px 23px;
	background-size: 100% 100%;
	background-image: url('https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/11/eeff793034c24062bc69a216b4a197e0_587x635.png');
	.loginMain {
		background: #fff;
		width: 436px;
		// height: 380px;
		height: 487px;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.resetPwdBox {
			padding-top: 40px;
		}
	}
	.resetPwd {
		width: 436px;
		height: 487px;
	}
	.loginHeard {
		position: relative;
		.welcomeMessage {
			color: #ffffff;
			display: flex;
			flex-direction: column;
			.welcome {
				font-size: 30px;
				font-weight: bold;
				line-height: 40px;
				margin-bottom: 2px;
			}
			.title {
				font-size: 36px;
				font-weight: bold;
				line-height: 40rx;
				margin-bottom: 3px;
			}
			.hint {
				font-size: 18px;
				font-weight: 500;
				line-height: 40px;
				margin-bottom: 6.8px;
			}
		}
		.resetPasswords {
			font-size: 32px;
			font-weight: bold;
			line-height: 40px;
			margin-bottom: 28px;
			color: #fff;
		}
		.icon-sohu-quxiao {
			position: absolute;
			right: 0;
			font-size: 22px;
			top: 0;
			cursor: pointer;
			color: #fff;
		}
	}

	.tabs {
		margin-top: 16px;
		margin-bottom: 11px;
		display: flex;
		align-items: center;
		justify-content: center;

		.tab_item {
			color: #333333;
			font-size: 16px;
			font-weight: normal;
			line-height: 40px;
			padding: 0 20px;
			text-align: center;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}

			&::after {
				content: '';
				display: block;
				background: transparent;
				width: 40px;
				height: 19px;
				margin: auto;
			}

			&.active {
				color: $theme-color;
				font-size: 16px;
				font-weight: bold;
				line-height: 40px;
				&::after {
					width: 40px;
					height: 19px;
					transform: translate(0px, -8px);
					background-size: contain;
					background-image: url('https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/10/80981a57268a4d868dc4d07d797ee46a_121x57.png');
					// background: #016bff;
				}
			}
		}
	}
	.scanBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		.QrCode {
			position: relative;
			padding: 9px;
			box-sizing: border-box;
			border: 1px solid #d1d1d1;
			border-radius: 10px;
			width: 194px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.img {
				width: 176px;
				height: 176px;
			}
			.appImg {
				width: 180px;
				height: 180px;
			}
			.describe {
				display: flex;
				align-items: center;
				font-size: 14px;
				font-weight: normal;
				line-height: 22px;
				color: #3d3d3d;
				margin-top: 3px;
				img {
					width: 15.43px;
					height: 12.41px;
					margin-right: 5.57px;
				}
			}
			.overdueBox {
				position: absolute;
				top: 0;
				width: 190px;
				height: 190px;
				border-radius: 8px;
				cursor: pointer;
			}
		}
		.WeChatHint {
			font-size: 18px;
			font-weight: 500;
			line-height: normal;
			color: #3d3d3d;
			margin-bottom: 33px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 24px;
			.gray {
				color: #b3b3b3;
				font-size: 14px;
				font-weight: normal;
				line-height: 22px;
				margin-top: 8px;
			}
		}
		.hint {
			margin-top: 15px;
			font-size: 13px;
			font-weight: normal;
			line-height: 22px;
			display: flex;
			align-items: center;
			color: #3d3d3d;
			span {
				color: #ff6c27;
			}
			.el-icon-circle-plus-outline {
				color: #ff6c27;
				margin: 0 1.5px;
			}
		}
	}
	.appQrCode {
		padding-top: 16px;
	}
	.el-form-item {
		margin-bottom: 20px;
		width: 100%;

		.el-input {
			width: 340px;
			height: 50px;
			border-radius: 8px;
			background: #f3f3f3;
		}
		.el-input__inner {
			border: none;
			box-shadow: none;
			height: 50px;
			background: #f3f3f3;
			color: #333333;
			border-radius: 8px;
		}

		.getCode {
			width: 340px !important;
			display: flex;
			align-items: center;
			background: #f3f3f3;
			border-radius: 8px;
			.el-input {
				width: 78%;
				margin-right: 16px;
			}
			.el-input__inner {
				border: none;
				box-shadow: none;
				height: 50px;
				background: #f3f3f3;
				color: #333333;
				border-radius: 8px;
			}

			.el-button {
				flex: 1;
				border-radius: 3.19px;
				color: $theme-color;
				font-size: 14px;
				border: 0px solid #000;
				background: #f3f3f3;
				border-radius: 8px;
				height: 50px;
				&[disabled] {
					opacity: 0.4;
				}
			}
		}
	}

	.loginBtn {
		width: 340px !important;
		height: 50px !important;
		margin-top: 24px;
		background: $theme-color;
		border-radius: 10px !important;
		box-shadow: none;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;

		&[disabled] {
			opacity: 0.4;
		}
	}
	.loginBtn:hover {
		background: $theme-color;
		opacity: 0.4;
	}

	.registerBtn {
		margin-top: 6px;
	}
	.getBack {
		color: #33333330;
		font-size: 12px;
		font-weight: normal;
		line-height: 20px;
		margin-top: 10px;
		width: 340px;
		cursor: pointer;
	}
	.bottomRadio {
		margin-top: 20px;
		display: flex;
		width: 340px;
		align-items: center;
		justify-content: center;
		.icon-sohu-danxuanweixuanzhong {
			color: #33333350;
			font-size: 18px;
		}
		.icon-sohu-xuanzhong {
			color: $theme-color;
			font-size: 18px;
		}

		.service {
			font-size: 14px;
			color: #33333350;
			margin-left: 4px;
			span {
				cursor: pointer;
				color: $theme-color;

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	.otherLogin {
		position: absolute;
		bottom: 46px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.otherLoginText {
			width: 360px;
			height: 20px;
			display: flex;
			align-items: center;

			span {
				font-size: 14px;
				font-weight: 500;
				line-height: normal;
				color: #d1d1d1;
				margin: 0 25px;
			}
			.bit {
				flex: 1;
				height: 1px;
				width: 100%;
				background-color: #d1d1d1;
			}
		}
		.WeChat {
			width: 40px;
			height: 40px;
			margin-top: 16px;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}

	.userNameBox {
		position: relative;
		.bottomError {
			display: flex;
			align-items: flex-end;
			width: 340px;
			justify-content: flex-end;
			// position: absolute;
			// top: 130px;

			.forgotPassword {
				color: #ff6c27;
				font-size: 12px;
				cursor: pointer;

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	.WxBindingPhoneBox {
		width: 100%;
		padding-top: 28px;
		display: flex;
		flex-direction: column;
		align-content: center;
		.WxBindingPhoneTitle {
			font-size: 18px;
			font-weight: 500;
			line-height: normal;
			color: #3d3d3d;
			margin-bottom: 8px;
			text-align: center;
		}
		.WxBindingPhoneHint {
			font-size: 14px;
			font-weight: normal;
			line-height: 14px;
			color: #b3b3b3;
			margin-bottom: 24px;
			text-align: center;
		}
	}

	.el-button {
		height: 40px;
		width: 100%;
		color: #fff;
		border-radius: 3.19px;
		font-size: 16px;
	}

	.bottomText {
		color: #165dff;
		font-size: 14px;
		margin-top: 32px;
		text-align: center;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}

	.phoneBox {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 340px;
		height: 50px;
		margin-bottom: 22px;
		border-radius: 8px;
		background: #f3f3f3;
		.typeText {
			font-size: 14px;
			font-weight: normal;
			line-height: 16px;
			width: 54px;
			padding: 0 10px 0 16px;
			border-right: 1px solid #d8d8d8;
		}
		.el-form-item {
			margin-bottom: 0;
			.el-input {
				flex: 1;
				height: 50px;
				border-radius: 8px;
				background: #f3f3f3;
				width: 286px;
			}
			.el-input__inner {
				flex: 1;
				width: 286px;
				border: none;
				box-shadow: none;
				height: 50px;
				background: #f3f3f3;
				color: #333333;
				border-radius: 8px;
			}
		}
	}
}
.loginDialog .el-dialog {
	border-radius: 26px !important;
	padding: 0;
	box-shadow: none;
	.loginBtn.el-button.is-disabled {
		background: #ff6c27 !important;
	}
	.el-dialog__body {
		background: #ff6c27;
	}
}
.iframe {
	border: medium;
	position: absolute;
	top: 0px;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	transform: scale(0.9);
}
</style>
