<template>
    <div>
    <DialogBox
        confirmText="保存规则"
        showBottomBtn
        appendToBody
        v-model="dialogVisible"
        class="Anti-Har"
        @confirm="addClick"
    >
        <div slot="header_title"><h4 style="width: 100%;text-align: center;">防骚扰设置</h4></div>
        <div class="setRow">
			<div class="setRow_title">规则名称</div>
			<q-input placeholder="请输入" :maxlength="30" v-model="details.title" show-word-limit></q-input>
		</div>

        <div class="setRow">
			<div class="setRow_title">防广告</div>
            <div class="setRow_item">
                <div class="setRow_text"><div class="icon-sohu-line"></div>消息包含关键词触发</div>
                <el-switch 
                v-model="details.enableAd" 
                @change="handlePreventAd"
                active-color="#13ce66"></el-switch>
            </div>
            <div class="setRow_line"></div>
            <div class="keyWord_list">
                <template v-for="item,index in details.adWordList">
                    <div class="keyWord_item" v-if="item !=null">
                        <div class="keyWord_item_flex">
                            <div class="setRow_text"><div class="icon-sohu-line"></div>关键词</div>
                            <q-input placeholder="请输入关键词" class="key_word_input" :maxlength="20" v-model="details.adWordList[index]"></q-input>
                        </div>
                        <i class="icon-sohu-guanbi2" @click="removeKeyWord(index)" style="margin-left: 4px;"></i>
                        <div class="setRow_line"></div>
                    </div>
                </template>
                
            </div>
            <q-button :disabled="disabled" type="theme" class="add_Key_Word" @click="addKeyWord">
                <i class="el-icon-plus"></i>
                添加关键词</q-button>
		</div>
        

        <div class="setRow">
			<div class="setRow_title">防刷屏</div>
            <div class="setRow_item">
                <div class="setRow_text">
                    <div class="icon-sohu-line"></div>
                    <span>消息长度超过</span>
                    <el-input @input="handleFontNumber" @blur="handleFontNumberBlur" type="number" class="setRow_text_input" :maxlength="maxLength" placeholder="请输入" v-model="details.screenLimit" ></el-input>
                    <span>字</span>
                </div>
                <el-switch 
                v-model="details.enableScreen" 
                @change="handleisFontNumber"
                active-color="#13ce66"></el-switch>
            </div>
		</div>
        <div class="setRow_line"></div>
        <div class="setRow">
			<div class="setRow_title">操作设置</div>
			<el-select v-model="details.punishType" placeholder="选择操作" clearable filterable>
                <el-option v-for="item,index in options"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            
		</div>

        <div class="setRow">
			<div class="setRow_title">适用群聊<i>*若群聊有设置规则，选择后适用新的规则</i></div>
            <!-- filterable -->
			<el-select v-model="activeGroupList" @change="handleGroupList" collapse-tags filterable multiple placeholder="选择群聊" clearable filterable >
                <el-option v-for="item in myGroupList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"  
                    >
                </el-option>
            </el-select>
		</div>

    </DialogBox>
    
        <subConfirmDialogBox ref="subConfirmDialogBoxRef" @confimActiveGroupList="handleGrounpList"/>
    </div>
</template>

<script>
import DialogBox from '@/components/dialogBox.vue';
import subConfirmDialogBox from './subConfirmDialogBox.vue';
export default {
    components:{
        DialogBox,
        subConfirmDialogBox
    },
    provide() {
		return {
			groupAdminList: this.open,
		};
	},
    watch:{
        //监听群组变化
        activeGroupList(newVal,oldVal){
            //过滤新群组和旧群组
            let array = newVal.filter(value => !this.oldGroupList.includes(value))
            if(array.length){
                let activeTrue = 0
                let activeFalse = 0
                this.myGroupList.map((item)=>{
                    if(item.disturb && array.includes(item.id)){
                        activeTrue += 1 
                    }else{
                        activeFalse += 1    
                    }
                })
                if(activeTrue){
                    this.isTwoConfim = false
                    // console.log('选了已有规则的群')
                }else{
                    this.isTwoConfim = true
                    // console.log('选了都无规则的群')
                }
            }
        }
    },
    data(){
        return{
            dialogVisible:false, //弹窗
            subdialogVisible:false, //子弹窗
            disabled:false, 
            maxLength:4,
            details:{  //提交信息
                title:'',
                adWordList:[],
            },
            maxKeyWords:100, //最长的关键词条数
            options:[ //操作
                {
                    label:'踢出群聊',
                    value:1
                },
                {
                    label:'不展示消息',
                    value:2
                }
            ],
            activeGroupList:[], //选中的群聊
            oldGroupList:[], //已关联规则的群聊
            myGroupList:[], //群主的所有群聊
            isTwoConfim:true,
        }
    },
    methods:{
        //子弹窗反馈事件
        handleGrounpList(val){
            if(val) this.isTwoConfim = true; this.addClick()
        },
        //操作选择群组
        handleGroupList(val){
            console.log('操作...',val)
        },
        //字符串转数字数组
        strToArr(str,num){
            let newArr = []
            str.split(',').map((item)=>{
                if(num){
                    if(item) newArr.push(parseInt(item))
                }else{
                    if(item) newArr.push(item)
                }     
            })
            return newArr
        },
        //数组转字符串
        arrToStr(arr){
            let newStr = ''
            arr.map((item)=>{  
                if(item) newStr = `${newStr? newStr + ',' : ''}${item}`
            })
            return newStr
        },
        //获取自己的群聊 可以选择的群聊
        async getMyGroup(){
            this.myGroupList = []
            let res = await this.$http.imGroupIsLeader() //获取属于自己的群
            if(res.code == 200){
                this.myGroupList = res.data
                var activeNewArr = []
                this.myGroupList.map((item)=>{
                    if(this.activeGroupList.includes(item.id)) activeNewArr.push(item.id)
                })
            this.activeGroupList = activeNewArr
            } 
		},
        //获取回显数据
        async getAntiHarSet(data){
            let res = await this.$http.imGroupNoHarassDetails(data.id) 
            if(res.code == 200){
                if(res.data){
                    this.details = {
                        ...res.data,
                        adWordList : this.strToArr(res.data.adWordList),
                        groupList : this.strToArr(res.data.groupList,true),
                    }
                    this.activeGroupList = this.strToArr(res.data.groupList,true)
                    this.oldGroupList = this.activeGroupList
                }else{
                    //无数据填充模板
                    this.details = {
                        title:'',
                        adWordList:[],
                    }
                    this.activeGroupList = []
                }
            }        
        },
        //删除关键词
        removeKeyWord(index){
            this.$set(this.details.adWordList,index,null)
            // console.log('删除关键词',index,this.details.adWordList)
            if(this.details.adWordList.length < this.maxKeyWords) this.disabled = false
        },
        //添加关键词
        addKeyWord(){
            console.log('添加关键词')
            this.details.adWordList.push('')
            if(this.details.adWordList.length >= this.maxKeyWords) this.disabled = true
        },
        //打开弹窗
        async open(data){
            this.dialogVisible = true   
            await this.getAntiHarSet(data);  
            await this.getMyGroup();       
        },
        //提交规则
        async addClick(close){
            //未填完整 弹窗提示
            if(!this.details.title){
                this.$message.error('请填写规则名称')
            }else if(this.details.screenLimit < 50){
                if(this.details.screenLimit){
                    this.$message.error('限制字数不少于50字')
                }else if(!this.details.screenLimit && details.enableScreen){
                    this.$message.error('请填写限制字数')
                } 
            }else if(!this.arrToStr(this.details.adWordList) && this.details.enableAd){
                this.$message.error('请添加关键词')
            }else if(this.isTwoConfim){
                let params = {
                    ...this.details,
                    adWordList : this.arrToStr(this.details.adWordList),
                    groupList : this.arrToStr(this.activeGroupList),
                    // screenLimit : parseInt(this.details.screenLimit)
                }
                // console.log('提交设置',this.details,params)
                let res = await this.$http.imGroupNoHarass(params)
                if(res.code == 200){
                    this.$message.success('保存成功')
                    this.dialogVisible = false
                }
            }else{
                this.$refs.subConfirmDialogBoxRef.open()
            }
            close(true)
        },
        handlePreventAd(){
            console.log('切换防广告')
        },
        handleFontNumber(val){
            if(val.length > this.maxLength){
                val = val.toString().slice(0,this.maxLength)
                // this.details.screenLimit = val
                this.$set(this.details,'screenLimit',val)
            }
            console.log('控制输入限制字数',val.toString().slice(0,this.maxLength),this.details.screenLimit,val)
        },
        handleFontNumberBlur(val){
            if(this.details.screenLimit < 50){
                this.$message.error('限制字数不少于50字')
            }
        },
        handleisFontNumber(){
            console.log('切换防刷屏')
        }
    }
}
</script>

<style lang="scss">
.el-select-dropdown__item.selected span{
    color: #FF6C27!important;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected span{
    color: #FF6C27!important;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after{
    color: #FF6C27!important;
}
.Anti-Har-sub{
    .dialogBox.el-dialog .el-dialog__body p{
        width: 200px;
        margin: 0 auto;
        font-size: 16px;
        font-weight: normal;
        line-height: 22px;
        text-align: center;
        color: #333333;
        padding: 20px 0 10px 0;
    }
}
.Anti-Har{
    /* 针对Webkit浏览器，如Chrome、Safari */
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    /* 针对Firefox浏览器 */
    input[type="number"] {
        -moz-appearance: textfield;
    }
    input[type="number"] {
        width: 100%;
        height: 100%;
        -webkit-appearance: textfield; /* 针对Webkit浏览器 */
        -moz-appearance: textfield;    /* 针对Firefox浏览器 */
        appearance: textfield;          /* 针对最新标准 */
    }
     .dialogBox.el-dialog .el-dialog__body{
        max-height: 800px;
        overflow-y: scroll;
        overflow-x: hidden;
        
    }
    .dialogBox.el-dialog .el-dialog__body::-webkit-scrollbar {
        display: none;
    }
    .setRow .q-button.theme{
        width: 130px;
        height: 36px;
        border-radius: 4px;
        opacity: 1;
        background: rgba(255, 108, 39, 0.1);
        font-size: 14px;
        border: 0;
        font-weight: normal;
        color: #FF6C27;
    }
    .setRow_text_input{
        width: 100%;
        height: 100%;
    }
    .setRow_line{
        width: 436px;
        height: 0px;
        opacity: 1;
        border-top: 1px solid #E7E7E7;
        margin: 12px 0;
    }
    .setRow {
        margin-top: 23px;
        .el-input .el-input__count .el-input__count-inner{
            background: #f3f3f3;
            color: rgba(255, 108, 39,0.6);
        }
        .keyWord_item{
            // height: 34px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            overflow: hidden;
            .keyWord_item_flex{
                width: 410px;
                display: flex;
                align-items: center;
            }
            .key_word_input.q-input .el-input__inner{
                background: #fff;
                height: 30px;
                overflow: hidden;
            }
            .setRow_text{
                margin-right: 4px;
            }
        }
        .el-select{
            width: 100%;
        }
        .setRow_title {
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
            i{
                font-style: normal;
                text-decoration: none;
                font-size: 12px;
                font-weight: normal;
                color: rgba(0, 0, 0, 0.26);
                margin-left: 10px;
            }
        }
        .setRow_item{
            width: 436px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            margin: 16px 0;
            .setRow_text_input{
                text-align: center;
                width: 76px;
                height: 28px;
                border-radius: 4px;
                opacity: 1;
                background: #FFFFFF;
                box-sizing: border-box;
                border: 1px solid #E7E7E7;
                margin: 0 11px;
                .el-input{
                    width: 100%;
                    height: 100%;
                    .el-input__inner{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .setRow_text{
            color: #333333;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            font-size: 14px;
            font-weight: 500;
            .q-input .el-input__inner, .q-input .el-textarea__inner{
                background: #FFFFFF;
            }
            .icon-sohu-line{
                display: inline-block;
                width: 11px;
                height: 1px;
                background: #ACACAC;
                margin-right: 8px;
            }
        }
    }
}

</style>