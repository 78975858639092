import { get, postFile, postJson, putJson } from "@/utils/request";
export default {
   // 获取用户信息
   getUserProfile (data) {
      return get(`/app/user/profile`, data);
   },
   // 修改用户信息
   editUserProfile (data) {
      return postJson(`/app/user/profile`, data);
   },
   // 获取点赞数
   getUserStat (data) {
      return get(`/app/user/stat`, data);
   },
   // 获取用户认证状态
   getPayAccount (data) {
      return get(`/pay/account/account`, data);
   },
   // 获取视频
   getVideoCenter (data) {
      return get(`/app/api/content/video/page/center`, data);
   },
   // 获取图文
   getArticleCenter (data) {
      return get(`/app/api/content/article/page/center`, data);
   },
   // 获取问答
   getQuestionCenter (data) {
      return get(`/app/api/question/page/center`, data);
   },

   // 获取诗歌
   getPoetry (data) {
      return get(`/admin/content/poetry/page/center`, data);
   },
   // 获取散文
   getProse (data) {
      return get(`/admin/content/prose/page/center`, data);
   },

   // 获取收藏的视频/图文
   getCollectList (data) {
      return get(`/app/user/collect/list`, data);
   },
   // 获取收藏的问答
   getCollectQuestionList (data) {
      return get(`/app/question/collect/list`, data);
   },
   // 获取收藏的剧集
   getCollectDjList (data) {
      return get(`/app/api/dj/collect/list`, data);
   },

   // 获取收藏的时候诗歌散文
   getCollectPoetryProse (data) {
      return get(`/app/user/collect/list`, data);
   },

   // 获取用户列表
   userPage (data) {
      return get(`/app/api/common/user/page`, data);
   },
   // 热门推荐
   getHotWordList (data) {
      return get(`/app/api/hot/word/list`, data);
   },
   // 获取用户信息
   getUserInfo (data) {
      return get(`/system/user/getInfo`, data);
   },
   //反馈与建议
   getFeedbackInfo (data) {
      return postJson(`/admin/feedbackInfo`, data);
   },
   //填写邀请码
   inviteCreate (data) {
      return postJson(`/app/user/invite/create`, data);
   },
   //举报图文，视频，问答，商单
   addReportInfo (data) {
      return postJson(`/app/operate/addReportInfo`, data);
   },
   // 邀请用户累计收益
   getInviteUserIncome (data) {
      return get(`/app/user/invite/statistics`, data);
   },
   //获取当前角色可否入驻
   getRoleIsEntry (giveRoleList) {
      return get(`/admin/api/platformRole/listByRoleCodes`, { giveRoleList })
   },
   //获取当前用户角色列表
   getRoleList () {
      return get('/system/platformRole/queryPlatformRoleList');
   },
   //修改作品图文
   updateArticle (data) {
      return putJson("/admin/article", data);
   },
   //修改作品视频
   updateVideo (data) {
      return putJson("/admin/video", data);
   },
   //修改作品问答
   updateQuestion (data) {
      return putJson("/admin/question", data);
   },
   //批量修改视频状态
   updateVideoAll (data) {
      return postJson("/admin/video/update/batch/state", data);
   },
   //批量修改视频状态
   updateArticleAll (data) {
      return postJson("/admin/article/update/batch/state", data);
   },
   //批量修改视频状态
   updateQuestionAll (data) {
      return postJson("/admin/question/update/batch/state", data);
   },
};
