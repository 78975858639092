<template>
	<el-scrollbar
		v-show="show"
		ref="scrollBox"
		class="group-member-choose"
		:style="{ left: pos.x + 'px', top: pos.y - 300 + 'px' }"
	>
		<div v-for="member in showMembers" :key="member.id">
			<ChatGroupMember
				:activeMember="showMembers[activeIdx]"
				:member="member"
				@click.native="onSelectMember(member)"
			></ChatGroupMember>
		</div>
	</el-scrollbar>
</template>

<script>
import ChatGroupMember from './ChatGroupMember.vue';
export default {
	name: 'chatAtBox',
	components: {
		ChatGroupMember,
	},
	props: {
		// 输入框的内容
		searchText: {
			type: String,
			default: '',
		},
		// 人员列表
		members: {
			type: Array,
		},
	},
	data() {
		return {
			show: false,
			pos: {
				x: 0,
				y: 0,
			},
			activeIdx: 0,
			showMembers: [],
		};
	},
	methods: {
		init() {
			this.showMembers = [];
			this.members.forEach((m) => {
				// if (this.searchText && m.alias.includes(this.searchText)) {
				// 	this.showMembers.push(m);
				// }
				// if (this.searchText && m.nickName.includes(this.searchText)) {
				// 	this.showMembers.push(m);
				// }
				// console.log('@名字列表',m,this.searchText)
				if ( m.alias?.includes(this.searchText) || m.nickName?.includes(this.searchText) || m.userName?.includes(this.searchText)) {
					this.showMembers.push(m);
				}
			});
			this.activeIdx = this.showMembers.length > 0 ? 0 : -1;
			this.$nextTick(() => {
				this.$refs.scrollBox.wrap.scrollTop = 0;
			});
		},
		open(pos) {
			this.show = true;
			this.pos = pos;
			this.init();
		},
		close() {
			this.show = false;
		},
		// 上一个
		moveUp() {
			if (this.activeIdx > 0) {
				this.activeIdx--;
				this.scrollToActive();
			}
		},
		// 下一个
		moveDown() {
			if (this.activeIdx < this.showMembers.length - 1) {
				this.activeIdx++;
				this.scrollToActive();
				console.log(this.activeIdx, 'this.activeIdx');
			}
		},
		// 选中
		select() {
			if (this.activeIdx >= 0) {
				this.onSelectMember(this.showMembers[this.activeIdx]);
			}
			this.close();
		},
		// 设置滚动条位置
		scrollToActive() {
			if (
				this.activeIdx * 45 - this.$refs.scrollBox.wrap.clientHeight >
				this.$refs.scrollBox.wrap.scrollTop
			) {
				this.$refs.scrollBox.wrap.scrollTop += 140;
				if (
					this.$refs.scrollBox.wrap.scrollTop >
					this.$refs.scrollBox.wrap.scrollHeight
				) {
					this.$refs.scrollBox.wrap.scrollTop =
						this.$refs.scrollBox.wrap.scrollHeight;
				}
			}
			if (this.activeIdx * 40 < this.$refs.scrollBox.wrap.scrollTop) {
				this.$refs.scrollBox.wrap.scrollTop -= 140;
				if (this.$refs.scrollBox.wrap.scrollTop < 0) {
					this.$refs.scrollBox.wrap.scrollTop = 0;
				}
			}
		},
		// 选中成员
		onSelectMember(member) {
			this.$emit('select', member);
			this.show = false;
		},
	},
	watch: {
		searchText: {
			handler(newText, oldText) {
				this.init();
			},
		},
	},
};
</script>

<style scoped lang="scss">
.group-member-choose {
	position: fixed;
	width: 180px;
	height: 300px;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
	padding: 6px 0;
	box-sizing: border-box;
	overflow: hidden;
}
</style>
