import baseUrl from './baseUrl';
let Socket = null;
let store = null;
let meClose = false;  //是否主动关闭
/**
 * 建立websocket连接
 */
export const createSocket = (vuex) => {
	vuex && (store = vuex);
	Socket = new WebSocket(baseUrl.wsUrl + '?token=' + store.state.token);
	console.log('建立websocket连接');
	Socket.onopen = onopenWS;
	Socket.onmessage = onmessageWS;
	Socket.onerror = onerrorWS;
	Socket.onclose = oncloseWS;
};

/**打开WS之后发送心跳 */
const onopenWS = () => {
	console.log('WebSocket:已连接');
	meClose = false;
	heartCheck.reset().start(); //重置，发送心跳
};

/**
 * 发生错误的回调
 */
const onerrorWS = () => {
	console.log('WebSocket:发生错误');
	reconnect(); //重新链接
};

/**WS数据接收统一处理 */
const onmessageWS = (e) => {
	store.commit('setMessage', e.data); //存入vuex中
	// console.log('WS接收到了数据....',e.data)
	heartCheck.reset().start(); //重置，发送心跳
};

/**
 * 发送数据但连接未建立时进行处理等待重发
 * @param {any} message 需要发送的数据
 */
const connecting = (message) => {
	setTimeout(() => {
		if (Socket.readyState === 0) {
			connecting(message);
		} else {
			Socket.send(JSON.stringify(message));
		}
	}, 4000);
};

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
export const sendWSPush = (message) => {	
	// console.log('发送数据',message,Socket)
	if (Socket !== null && Socket.readyState === 3) {
		Socket.close();
		createSocket();
	} else if (Socket.readyState === 1) {
		Socket.send(JSON.stringify(message));
	} else if (Socket.readyState === 0) {
		connecting(message);
	}
};

/**
 *手动关闭服务
 */
export const socketClose = () => {
	console.log('手动关闭服务');
	meClose = true;
	Socket && Socket.close();
};

/**
 * 链接关闭的回调
 */
const oncloseWS = () => {
	if(meClose){
		console.log('WebSocket:主动关闭');
		heartCheck.reset(); //关闭心跳定时器
	}else{
		console.log('WebSocket:被动关闭');
		reconnect(); //重新链接
	}
};

//避免重复连接
var lockReconnect = false,
	tt;

/**
 * websocket重连
 */
function reconnect() {
	if (lockReconnect) {
		return;
	}
	lockReconnect = true;
	tt && clearTimeout(tt);
	tt = setTimeout(function () {
		console.log('重连中...');
		lockReconnect = false;
		createSocket();
	}, 2000);
}

/**
 * websocket心跳检测
 */
var heartCheck = {
	timeout: 15000,
	timeoutObj: null,
	serverTimeoutObj: null,
	reset: function () {
		clearTimeout(this.timeoutObj);
		clearTimeout(this.serverTimeoutObj);
		return this;
	},
	start: function () {
		var self = this;
		this.timeoutObj && clearTimeout(this.timeoutObj);
		this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
		this.timeoutObj = setTimeout(function () {
			Socket.send('http_ping');
			self.serverTimeoutObj = setTimeout(function () {
				console.log('关闭服务');
				Socket.close();
			}, self.timeout);
		}, this.timeout);
	},
};
